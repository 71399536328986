import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { gsap } from "gsap-trial";

export default function RelatedWorks(post) {

  const { 
    obj
  } = post;

  const GET_ARTISTS = gql`
  query getAllPosts($name: ID!) {
    artist(id: $name, idType: NAME) {
    id
    projects {
      nodes {
        title
        uri
        slug
        featuredImage {
          node {
            srcSet
            altText
          }
        }
      }
    }
  }
}
`;
const id = obj;
  const { data } = useQuery(GET_ARTISTS, {
    variables: {
      name: id
  }
  });
  useEffect(() => {
    if(!!window.IntersectionObserver){
      let observer = new IntersectionObserver((entries, observer) => { 
        entries.forEach(entry => {
        if(entry.isIntersecting){
          gsap.to(entry.target.querySelectorAll('img'),
            {
            x: "200%",
            ease: "sine.in",
            duration: 1,
            stagger: {
              each: 0.4,
              from: "start",
              grid: "auto"
            }
          });
          observer.unobserve(entry.target);
        }
        });
      }, {rootMargin: "0px 0px 0px 0px"});
      document.querySelectorAll('.gridItem').forEach(img => { observer.observe(img) });
    }
    
    else;
  });


  
  if(data) {
    console.log(data.artist.projects);

  return (
      <>
      <div className="masonry">
      {data.artist.projects.nodes
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
      const obj = flexFields;
     
      for (const key in obj) {
        if(obj.featuredImage?.node?.srcSet) {
   
  
    return (
     <>
      
            <div key={obj.slug} className={`gridItem`} data-title={`pro_${obj.title}_pro`}>
                <Link to={`/projects/${obj.slug}`}>
                <div className="gridTitle" data-title={`pro_${obj.title}_pro`} ><h3 className="small">{obj.title}</h3></div>
                <div className="zoomed">
                <img className="zoomMe" srcSet={obj.featuredImage?.node?.srcSet} alt={obj.featuredImage?.node?.alt} />
                </div>
                <div className="gridArtist small"><h4>{id}</h4></div>
                </Link>
            </div>

     </>
    );
  }
}
return null;
} ) } 
      </div>
      </>
);
}
}
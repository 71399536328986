import React from "react";
import FeaturedProjects from "../components/FeaturedProjects";
import AllWorksLink from "../components/allWorks";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';
import { gql, useQuery } from "@apollo/client";

const HOMEPAGE= gql`
query getHomePage {
  page(id: "9", idType: DATABASE_ID) {
    seo {
      fullHead
    }
  }
}`;

export default function HomePage() {
        
  const {  loading, data } = useQuery(HOMEPAGE);
  if(loading) {
    return (
        <div id="loading"></div>
    )
  }
  if(data) {
  const fullHead = parse(data.page.seo.fullHead);
  const helmetContext = {};
  
  return (
    
    <>
      <HelmetProvider context={helmetContext}>
      <Helmet>
      <body id="homePage"></body>
      {fullHead}
      </Helmet>
    <FeaturedProjects />
    <AllWorksLink />
    </HelmetProvider>

    </>
  );
}
}
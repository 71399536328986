import React from "react";
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import Project from "../components/Project";
import MobileProject from "../components/MobileProject";





const ProjectPage = () => {
    
    const handleMediaQueryChange = (matches) => {
      // matches will be true or false based on the value for the media query
    }

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' }, undefined,  handleMediaQueryChange)
  
      return (
        <>
          { isMobile ? <MobileProject /> : <Project /> } 
        </>
      );
}
export default ProjectPage;
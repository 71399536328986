import React, {useEffect, useState} from "react";
import '../css/worksAll.css';
import ReactDOM from 'react-dom/client';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { gsap } from "gsap-trial";


const GET_CLIENTS = gql`
  query getAllPosts($slug: [String]) {
    clients(where: {slug: $slug}) {
      nodes {
        name
        slug
        projects {
          nodes {
            title
            slug
            projectId
            featuredImage {
              node {
                altText
                srcSet
                title
              }
            }
          }
        }
      }
    }
  }
`;

export default function ClientsArchive() {

  let { clienturi } = useParams();
  const { loading, error, data } = useQuery(GET_CLIENTS, {
    variables: {
      slug: clienturi
  }
  });
console.log(clienturi);
  const helmetContext = {};
  useEffect(() => {
    if(data) {
    
      const tl = gsap.timeline();


      
if(!!window.IntersectionObserver){
  let observer = new IntersectionObserver((entries, observer) => { 
    entries.forEach(entry => {
    if(entry.isIntersecting){
      gsap.to(entry.target.querySelectorAll('img'),
        {
        x: "200%",
        ease: "sine.in",
        duration: 1,
        stagger: {
          each: 0.4,
          from: "start",
          grid: "auto"
        }
      });
      observer.unobserve(entry.target);
    }
    });
  }, {rootMargin: "0px 0px 0px 0px"});
  document.querySelectorAll('.gridItem').forEach(img => { observer.observe(img) });
}

else;


document.querySelectorAll(".gridItem a").forEach(function(element) {
  const img = element.querySelectorAll("img");
  const imgAll = document.querySelectorAll("img");
  const animation = gsap.timeline({ paused: true });
  const animationAll = gsap.timeline({ paused: true });
  const text = element.querySelectorAll(".fades h3");
  const text2 = element.querySelectorAll(".fades h4");


  animationAll.to(imgAll, {opacity: 0.5, duration: .1, ease: "power1.inOut"});
  animation
  .to(text,
    {
      y: "50px",
      duration: .5,
      ease: "power1.inOut"
    }
  )
  .to(text2,
    {
      y: "-50px",
      duration: .5,
      ease: "power1.inOut"
    }
  )
  .to(img, 
    {
      scale: 1, 
      opacity: 1, 
      duration: .5, 
      ease: "power1.inOut"
    }
    )
    ;

  element.addEventListener("mouseenter", function () {
    animation.play();
  });
  element.addEventListener("mouseleave", function () {
    animation.reverse();
  });

});

    }});



  if(loading) {
    return (
        <div id="loading"></div>
    )
  }
  else if(data) {
  return (
    <HelmetProvider context={helmetContext}>
    <Helmet>
       <body id="works"></body>
      </Helmet>
      <div className="standardScroll">
    <section id="worksArchive" className="">
    <div className="masonry">
      {data.clients.nodes[0].projects.nodes
      .map((flexFields, key) => {
      const obj = flexFields;
      for (const key in obj) {
        return (
            <div className={`gridItem`} data-title={`pro_${obj.projectId}_pro`} key={obj.projectId}>
              <Link to={`/projects/${obj.slug}`}>
              <div className="gridTitle fades" data-title={`pro_${obj.projectId}_pro`} ><h3 className="small">{obj.title}</h3></div>
                <div className="zoomed">
                <img className="zoomMe" srcSet={obj.featuredImage?.node?.srcSet} alt={obj.featuredImage?.node?.altText} />
                </div>
                <div className="gridArtist fades small"><h4>{data.clients.nodes[0].name}</h4></div>
                </Link>
            </div>
        )
      }
      return null;
    } ) }
            </div>
    </section>
    </div>
    </HelmetProvider>

  );
  }
}

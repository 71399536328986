import React, {useEffect, useState} from "react";
import '../css/worksAll.css';
import ReactDOM from 'react-dom/client';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { gsap } from "gsap-trial";


const GET_CLIENTS = gql`
  query getAllPosts($slug: [String]) {
    clients(where: {slug: $slug}) {
      nodes {
        name
        slug
        projects {
          nodes {
            title
            slug
            projectId
            featuredImage {
              node {
                altText
                srcSet
                title
                sourceUrl(size: _1536X1536)
              }
            }
          }
        }
      }
    }
  }
`;

export default function ClientsArchiveList() {

  let { clienturi } = useParams();
  const { loading, error, data } = useQuery(GET_CLIENTS, {
    variables: {
      slug: clienturi
  }
  });
console.log(clienturi);
  const helmetContext = {};
  useEffect(() => {
    if(data) {
    
      document.querySelectorAll(".gridItemList a").forEach(function(element) {

        const animation = gsap.timeline({ paused: true });
        const text = element.querySelectorAll(".listImage");
  
        animation
        .to(text,
          {
            x: "100%",
            duration: 1,
            ease: "power1.inOut"
          }
        );
  
        element.addEventListener("mouseenter", function () {
          animation.play();
        });
        element.addEventListener("mouseleave", function () {
          animation.reverse();
        });
  
      });
  
      }});



  if(loading) {
    return (
        <div id="loading"></div>
    )
  }
  else if(data) {
  return (
    <HelmetProvider context={helmetContext}>
    <Helmet>
       <body id="works"></body>
      </Helmet>
      
      <div className="masonryList">

      {data.clients.nodes[0].projects.nodes
      .map((flexFields, key) => {
      const obj = flexFields;

      for (const key in obj) {
        let styles = {
          backgroundImage: "url('" + obj.featuredImage?.node?.sourceUrl + '',
          
        };
        return (
            <div className={`gridItemList`} data-title={`pro_${obj.projectId}_pro`} key={obj.projectId}>
                  <span className="gridItemListPseudo" style={styles}></span>
              <Link to={`/projects/${obj.slug}`}>
              <div className="listImage"></div>
              <div className="gridTwoTitle" data-title={`pro_${obj.projectId}_pro`} >
                <h3 class="large black">{obj.title}</h3>
                <div className="gridTwoArtist small">{}</div>
                </div>
                </Link>
            </div>
        )
      }
      return null;
    } ) }
            </div>
    </HelmetProvider>

  );
  }
}

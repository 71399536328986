import React, {useState} from "react";
import ClientsArchive from "./ClientsPage";
import { useParams } from 'react-router-dom';
import ClientsArchiveList from "./ClientsPageList";

export default function AllClientsPage() {
        
  const [cName, setClassName] = useState('jsListView');  
  let { clienturi } = useParams();
  function makeTitle(slug) {
    var words = slug.split('-');
  
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
  
    return words.join(' ');
  }
  return (
    
    <>
    <div className="standardScroll" id={cName}>
    <section className="pageTitle">
        <h1 className="large">{makeTitle(clienturi)}</h1>
        <div className="textMode">
          <div className="gridView">
      <button 
      onClick={() => setClassName('jsGridView')}
      title="Grid View"
      className="imageTog small">Grid</button> 
      </div> 
      <div className="listView">
      <button 
      onClick={() => setClassName('jsListView')}
      title="List View"
      className="textTog small">List</button>
      </div>
    </div>
      </section>
      
    <section id="worksArchive" className={cName}>
    { cName === 'jsGridView' ? <ClientsArchive /> : <ClientsArchiveList /> } 
    </section>
    </div>
    </>
  );
}
import React, {useEffect} from "react";
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';
import '../css/project.css';
import { gql, useQuery } from "@apollo/client";
import TextBlock from "../flexible_fields/TextBlock";
import SingleProjectImage from "../flexible_fields/SingleProjectImage";
import SmallSingleProjectImage from "../flexible_fields/SmallSingleProjectImage";
import RelatedWorks from "../flexible_fields/RelatedWorks";
import CreditsBlock from "../flexible_fields/CreditsBlock";
import DoubleImageRightAndVideoBlock from "../flexible_fields/DoubleImageRightAndVideoBlock";
import DoubleImageLeftAndVideoBlock from "../flexible_fields/DoubleImageLeftAndVideoBlock";
import DoubleProjectVideo from "../flexible_fields/DoubleProjectVideo";
import DoubleProjectImage from "../flexible_fields/DoubleProjectImage";
import FullScreenVideo from "../flexible_fields/FullScreenVideo";
import SmallProjectVideo from "../flexible_fields/SmallProjectVideo";
import SingleProjectVideo from "../flexible_fields/SingleProjectVideo";
import FullScreenImage from "../flexible_fields/FullScreenImage";
import { gsap } from "gsap-trial";
import { Observer } from "gsap-trial/Observer";

gsap.registerPlugin(Observer);


const PAGEQUERY = gql`
query PageId($url: ID!) {
  page(id: $url, idType: URI) {
    id
    title
    slug
    seo {
      fullHead
    }
    buildYourPage {
      addContentBlock {
        ... on Page_Buildyourpage_AddContentBlock_DoubleProjectImage {
          addText
          fieldGroupName
          leftImage {
            altText
            srcSet
            sourceUrl
          }
          rightImage {
            srcSet
            sourceUrl
            altText
          }
          optionalContent
          optionalHeading
        }
        ... on Page_Buildyourpage_AddContentBlock_SingleProjectImage {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          addImage {
            altText
            srcSet
            sourceUrl(size: _2048X2048)
            title
            uri
            slug
          }
        }
        ... on Page_Buildyourpage_AddContentBlock_SmallSingleProjectImage {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          addImage {
            altText
            uri
            title
            status
            srcSet
            sourceUrl
            slug
          }
        }
        ... on Page_Buildyourpage_AddContentBlock_ProjectInfo {
          fieldGroupName
        }
        ... on Page_Buildyourpage_AddContentBlock_RelatedWorks {
          fieldGroupName
        }
        ... on Page_Buildyourpage_AddContentBlock_CreditsBlock {
          fieldGroupName
          addTextBlocks {
            fieldGroupName
            heading
            text
          }
        }
        ... on Page_Buildyourpage_AddContentBlock_TextBlock {
          content
          fieldGroupName
          heading
        }
        ... on Page_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          rightImage {
            altText
            uri
            title
            srcSet
            sourceUrl
          }
          videoLeft {
            title
            mediaItemUrl
          }
        }
        ... on Page_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          leftImage {
            altText
            srcSet
            sourceUrl
            title
            uri
          }
          videoRight {
            title
            mediaItemUrl
          }
        }
        ... on Page_Buildyourpage_AddContentBlock_FullScreenVideo {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          video {
            mediaItemUrl
            title
          }
        }
        ... on Page_Buildyourpage_AddContentBlock_SmallProjectVideo {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          video {
            mediaItemUrl
            title
          }
        }
        ... on Page_Buildyourpage_AddContentBlock_FullScreenImage {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          addImage {
            altText
            uri
            title
            status
            srcSet
            sourceUrl
            slug
          }
        }
        ... on Page_Buildyourpage_AddContentBlock_SingleProjectVideo {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          video {
            mediaItemUrl
            title
          }
        }
        ... on Page_Buildyourpage_AddContentBlock_DoubleProjectVideo {
          fieldGroupName
          videoLeft {
            mediaItemUrl
            title
          }
          optionalContent
          optionalHeading
          videoRight {
            mediaItemUrl
            title
          }
        }
      }
    }
  }
}
`;

export default function Pages() {
    let { myuri } = useParams();
    const { loading, error, data } = useQuery(PAGEQUERY, {
        variables: {
            url: myuri
        }
    });
   
    useEffect(() => {

  
 
      if(!!window.IntersectionObserver){
        let observer = new IntersectionObserver((entries, observer) => { 
          entries.forEach(entry => {
          if(entry.isIntersecting){
            gsap.fromTo(entry.target,
              {
                x: 0,
                scale: 1.4,
              },
              {
              x: "200%",
              ease: "power1.in",
              scale: 1,
              duration: 2,
            });
            observer.unobserve(entry.target);
          }
          });
        }, {rootMargin: "0px 0px 0px 0px"});
        document.querySelectorAll('.imgreveal').forEach(img => { observer.observe(img) });
      }
      
      else;
  
      if(!!window.IntersectionObserver){
        let observerTwo = new IntersectionObserver((entries, observerTwo) => { 
          entries.forEach(entry => {
          if(entry.isIntersecting){
            console.log(entry.target.querySelector('hr'));
            gsap.to(entry.target.querySelectorAll('hr'),
              {
              x: "101%",
              ease: "power1.in",
              duration: 1,
            })
            gsap.to(entry.target.querySelectorAll('h3'),
              {
              y: "25px",
              ease: "power1.in",
              duration: 1,
            })
            gsap.to(entry.target.querySelectorAll('p'),
              {
              y: "-200px",
              ease: "power1.in",
              duration: 1,
            });
            observerTwo.unobserve(entry.target);
          }
          });
        }, {rootMargin: "0px 0px 0px 0px"});
        document.querySelectorAll('.hideShow').forEach(img => { observerTwo.observe(img) });
      }
      
      else;
  
      
      if(!!window.IntersectionObserver){
        let observerThree = new IntersectionObserver((entries, observerThree) => { 
          entries.forEach(entry => {
          if(entry.isIntersecting){
            console.log(entry.target.querySelector('hr'));
            gsap.to(entry.target.querySelectorAll('hr'),
              {
              x: "101%",
              ease: "power1.in",
              duration: 1,
            })
            gsap.to(entry.target.querySelectorAll('h2'),
              {
              y: "25px",
              ease: "power1.in",
              duration: 1,
            })
            gsap.to(entry.target.querySelectorAll('.creditItems'),
              {
              y: "401px",
              ease: "power1.in",
              duration: 1,
            })
            ;
            observerThree.unobserve(entry.target);
          }
          });
        }, {rootMargin: "0px 0px 0px 0px"});
        document.querySelectorAll('.hideShowCredits').forEach(img => { observerThree.observe(img) });
      }
      
      else;
  });

    if (loading) return <p>Loading posts…</p>;
    if (error) return <p>Error :( </p>;
  
  
    if(data.page !== null) {
      const helmetContext = {};
      const fullHead = parse(data.page.seo.fullHead);
      return (
        <>
        <HelmetProvider context={helmetContext}>
      <Helmet>
      <body id="standardPage"></body>
      {fullHead}
      </Helmet>
        <section className="pageTitle">
        <h1 className="large">{data.page.title}</h1>
        </section>    

        </HelmetProvider>
        <div className="hideMeTwo">

  <div id="masterWrapTwo">
  <div id="panelWrapTwo">

  

{data.page.buildYourPage.addContentBlock
.filter(({ __typename }) => __typename)
.map((flexFields, key) => {
const obj = flexFields;
const randomString = (length) => [ ...Array(length) ].map(() => (~~(Math.random() * 36)).toString(36)).join('');

for (const key in obj) {
  
if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_SingleProjectImage') {
  return (
  <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL SingleProjectImage">
      <SingleProjectImage key={randomString(14)}  obj={obj} />
    
    </section>
    )
}
else if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_SmallSingleProjectImage') {
  return (
  <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL SmallSingleProjectImage">
      <SmallSingleProjectImage key={randomString(14)}  obj={obj} />
    
    </section>
    )
}
else if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_RelatedWorks') {
  return (
  <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL RelatedWorks">
      <RelatedWorks key={randomString(14)}  obj={obj} />
    
    </section>
    )
}
else if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_CreditsBlock') {
  return (
  <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL CreditsBlock">
      <CreditsBlock key={randomString(14)}  obj={obj} />
    
    </section>
    )
}
else if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_TextBlock') {
  return (
      <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL TextBlock">
      <TextBlock key={randomString(14)}  obj={obj} />
    
        </section>
    )
}
else if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock') {
  return (
  <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL DoubleImageRightAndVideoBlock">
      <DoubleImageRightAndVideoBlock key={randomString(14)}  obj={obj} />
    
    </section>
    )
}
else if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock') {
  return (
  <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL DoubleImageLeftAndVideoBlock">
      <DoubleImageLeftAndVideoBlock key={randomString(14)}  obj={obj} />
    
    </section>
    )
}
else if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_DoubleProjectVideo') {
  return (
  <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL DoubleProjectVideo">
      <DoubleProjectVideo key={randomString(14)}  obj={obj} />
    
    </section>
    )
}
else if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_DoubleProjectImage') {
  return (
  <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL DoubleProjectImage">
      <DoubleProjectImage key={randomString(14)}  obj={obj} />
    
    </section>
    )
}
else if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_FullScreenVideo') {
  return (
  <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL FullScreenVideo">
      <FullScreenVideo key={randomString(14)}  obj={obj} />
    
    </section>
    )
}
else if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_SmallProjectVideo') {
  return (
  <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL SmallProjectVideo">
      <SmallProjectVideo key={randomString(14)}  obj={obj} />
    
    </section>
    )
}
else if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_SingleProjectVideo') {
  return (
  <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL SingleProjectVideo">
      <SingleProjectVideo key={randomString(14)}  obj={obj} />
    
    </section>
  )
}
else if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_FullScreenImage') {
  return (
  <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL FullScreenImage">
      <FullScreenImage key={randomString(14)}  obj={obj} />
    
    </section>
  )
}  else {
  return (null);
}
}
}
)}
</div>
  </div>


</div>

        </>
      );
    } else {

    return (
      <>
      <h1>404 PAGE</h1>
      </>
    );
}
}

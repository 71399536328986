import React from "react";
import { Link } from "react-router-dom";


export default function AllWorksLink() {
  

  return (
    <>
      <div id="allWorksLink">
        <Link className="small" to={`/works/all-work`}>
          ALL WORKS
        </Link>
      </div>
      </>
  );
}

import React from "react";

export default function TextBlock(post) {
   
  const {
    obj
  } = post;
  
  
    return (
     
      <div className={obj.fieldGroupName}>
        <div className="hideShow">
          <h3 className="small">{obj.heading}</h3>
          <hr />
          <div dangerouslySetInnerHTML={{__html: obj.content}}></div>
        </div>
      </div>
  
    );
  }  
import React from 'react';
import '../css/whatwedo.css';
import { Canvas} from "@react-three/fiber";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';
import AllWorksLink from "../components/allWorks";
import { useLoader } from "@react-three/fiber";
import { OrbitControls, OrthographicCamera } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense } from "react";
import { useEffect } from "react";
import { gsap } from "gsap-trial";
import { gql, useQuery } from "@apollo/client";


const Model = () => {
  const gltf = useLoader(GLTFLoader, "/final-vf.glb");
  

  return (
    <>
   
      <primitive object={gltf.scene} scale={0.5} makedefault/>
      
    </>
  );
};

const PAGE_QUERY = gql`
query PageId {
  pageBy(uri: "about") {
    id
    title
    slug
    status
    seo {
      fullHead
    }
    buildYourPage {
      addContentBlock {
        ... on Page_Buildyourpage_AddContentBlock_TextBlock {
          content
          fieldGroupName
          heading
        }
      }
    }
  }
}
`;

export default function WhatWeDo() {

  const { loading, error, data } = useQuery(PAGE_QUERY);

  const tl = gsap.timeline();

useEffect(() => {

  if(!!window.IntersectionObserver){
    let observer = new IntersectionObserver((entries, observer) => { 
      entries.forEach(entry => {
      if(entry.isIntersecting){
        gsap.to(entry.target.querySelectorAll('a'),
          {
          y: "-25px",
          ease: "power1.in",
          duration: 1,
        });
        observer.unobserve(entry.target);
      }
      });
    }, {rootMargin: "0px 0px 0px 0px"});
    document.querySelectorAll('#allWorksLink').forEach(img => { observer.observe(img) });
  }
  
  else;
  
  setTimeout(function () {
    const element = document.getElementById('refThree');
  element.classList.add('updated');
  }, 2000); 

}, []);

if(loading) {
  return (
      <div id="loading"></div>
  )
}
  if (error) return <p>Error :( </p>;
 
  
  if(data.page !== null) {

  }
  if(data) {

    const fullHead = parse(data.pageBy.seo.fullHead);
  const helmetContext = {};

  return (
    <>
    <HelmetProvider context={helmetContext}>
      <Helmet>
      <body id="aboutPage"></body>
      {fullHead}
      </Helmet>
      <section className="pageTitle">
        <h1 className="large">About</h1>
        </section>
    <div  id="smooth-wrapper" className="container">
    {data.pageBy.buildYourPage.addContentBlock
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
        const obj = flexFields;
if (flexFields.__typename === 'Page_Buildyourpage_AddContentBlock_TextBlock') {
        return (
      <div key={key} className="whatwedoContent">
        {obj.heading != null &&
          <>
        <h2 className="small" >{obj.heading}</h2>
        <hr />
        </>
        }
        <div className="innerHtml" dangerouslySetInnerHTML={{__html: obj.content}}></div>
    </div>
    )
        }
      }) }
      <div id="smooth-content" className="App whatWeDo">
        
        <Canvas id="refThree">
        
        <group>

        <ambientLight intensity={10} />
        
          <Suspense fallback={null}>
            <Model />
            <OrbitControls  autoRotate enableZoom={false} enablePan={false} enableRotate={false} minAzimuthAngle="Math.PI" maxAzimuthAngle="Math.PI" />
          </Suspense>
          <OrthographicCamera 
        zoom={100}
        position={[15, 15, 15]}
        makeDefault
         />
          </group>
        </Canvas>
        
      </div>
    
    </div>
    <AllWorksLink />
    </HelmetProvider>
    </>
  );
    }
}
import React, {useEffect, useState, useRef} from "react";
import '../css/worksAll.css';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { gsap } from "gsap-trial";


const GET_WORKS = gql`
  query getAllPosts {
    projects (first:100) {
        edges {
          node {
            title
            projectId
            slug
            artists {
              edges {
                node {
                  id
                  name
                }
              }
            }
            featuredImage {
              node {
                altText
                srcSet
                title
                sourceUrl(size: _1536X1536)
              }
            }
          }
        }
      }
  }
`;

export default function WorksList() {

  const { data } = useQuery(GET_WORKS);

  const helmetContext = {};

  useEffect(() => {
    if(data) {
    


    document.querySelectorAll(".gridItemList a").forEach(function(element) {

      const animation = gsap.timeline({ paused: true });
      const text = element.querySelectorAll(".listImage");

      animation
      .to(text,
        {
          x: "100%",
          duration: 1,
          ease: "power1.inOut"
        }
      );

      element.addEventListener("mouseenter", function () {
        animation.play();
      });
      element.addEventListener("mouseleave", function () {
        animation.reverse();
      });

    });

    }});

   
  
  if(data) {
  return (
    <HelmetProvider context={helmetContext}>
<Helmet>
       <body id="works"></body>
      </Helmet>
      
      <div className="masonryList">
      {data.projects.edges
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
      const obj = flexFields;
     
      for (const key in obj) {
          let styles = {
            backgroundImage: "url('" + obj.node.featuredImage?.node?.sourceUrl + '',
            
          };
        return (
            <div key={obj.node.slug} className={`gridItemList`}  data-title={`pro_${obj.node.projectId}_pro`}>
              <span className="gridItemListPseudo" style={styles}></span>
              <Link to={`/projects/${obj.node.slug}`}>
              <div className="listImage"></div>
              <div className="gridTwoTitle" data-title={`pro_${obj.node.projectId}_pro`} >
                <h3 class="large black">{obj.node.title}</h3>
                <div className="gridTwoArtist small">{obj.node.artists?.edges[0].node.name}</div>
                </div>
                </Link>
            </div>
        )
      }
      return null;
    } ) }
            </div>
    </HelmetProvider>

  );
  }
}

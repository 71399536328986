import React from "react";

export default function DoubleImageLeftAndVideoBlock(post) {
  const {
    obj
  } = post;
  
    return (
      <div className={obj.fieldGroupName}>
<div className="textOverImage">
            <h3 className="small">{obj.optionalHeading}</h3>
            <hr />
            <div dangerouslySetInnerHTML={{__html: obj.optionalContent}}></div>
          </div>
        <div className="imageContainer">
        <span className="imgreveal"></span>
        <img src={obj.leftImage.sourceUrl + '.webp'} onError={(e)=>{ if (e.target.src !== obj.leftImage.sourceUrl){
                e.target.onerror = null;
                 e.target.src= obj.leftImage.sourceUrl;}
            }
       }/>
          
        </div>

        <div className="videoContainer">
        <span className="imgreveal"></span>
        <video loop="true" autoplay="autoplay" id="vid" muted playsinline preload>
          <source src={obj.videoRight.mediaItemUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </div>

      </div>
    );
  }  
import React from 'react';
import './footer.css';
import { useMediaQuery } from 'react-responsive';
import OpenMenu from "./components/OpenMenu";
import MobileOpenMenu from "./components/MobileOpenMenu";
import { gsap } from "gsap-trial";

export default function Footer() {

  const handleMediaQueryChange = (matches) => {
    // matches will be true or false based on the value for the media query
  }

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' }, undefined,  handleMediaQueryChange)

  var element = document.getElementsByTagName("html")[0];
  var tl = gsap.timeline({ paused: true});
  var tl2 = gsap.timeline({ paused: true});

  function invertLight(e) {
    e.preventDefault();
    element.style.filter = "invert(0)";
    element.classList.remove("dark");
    element.classList.add("light");
  }

  function invertDark(e) {
    e.preventDefault();
    element.style.filter = "invert(1)";
    element.classList.remove("light");
    element.classList.add("dark");
  }

  setTimeout(function () {

  const lightIn = document.getElementById("lightIn");
  const darkIn = document.getElementById("darkIn");

    tl.fromTo("#lightIn", 0.5,
      {
      
      left: '100vw',
      opacity: 0,
      },
      {
        left: "-35px",
        opacity: 1,
        duration: 0.2,
      ease: 'power3.inOut',
      }
    )
    tl2.fromTo("#darkIn", 0.5,
      {
      
      left: '100vw',
      opacity: 0,
      },
      {
        left: "-35px",
        opacity: 1,
        duration: 0.2,
      ease: 'power3.inOut',
      }
    )
    document.getElementById("light").addEventListener('mouseenter', (event) => {
      tl.play();
  });
  document.getElementById("light").addEventListener('mouseleave', (event) => {
    tl.reverse();
  });
    document.getElementById("dark").addEventListener('mouseenter', (event) => {
      tl2.play();
  });
  document.getElementById("dark").addEventListener('mouseleave', (event) => {
    tl2.reverse();
  });

}, 3000);



  return (
    <>
    <footer>
      { isMobile ? <MobileOpenMenu /> : <OpenMenu /> } 
    </footer>
          <p id="invert">
            <span id="appearance" className="small">Appearance</span>
            <span id="light" className="light" onClick={invertLight}><span id="lightIn" className="small">Light</span></span><span id="dark" className="dark" onClick={invertDark}><span id="darkIn" className="small">Dark</span></span></p>
          </>
  )
}
import React from "react";
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import OurClients from "../components/OurClients";
import OurClientsMobile from "../components/OurClientsMobile";





const AllClients = () => {
    
    const handleMediaQueryChange = (matches) => {
      // matches will be true or false based on the value for the media query
    }

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' }, undefined,  handleMediaQueryChange)
  
      return (
        <>
          { isMobile ? <OurClientsMobile /> : <OurClients /> } 
        </>
      );
}
export default AllClients;
import React, {useEffect, useState} from "react";
import '../css/worksAll.css';
import parse from 'html-react-parser';
import ReactDOM from 'react-dom/client';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { gsap } from "gsap-trial";


const GET_ARTISTS = gql`
  query getAllPosts($slug: [String]) {
    artists(where: {slug: $slug}) {
      nodes {
        name
        slug
        projects {
          nodes {
            title
            slug
            projectId
            seo {
              fullHead
            }
            featuredImage {
              node {
                altText
                srcSet
                title
              }
            }
          }
        }
      }
    }
  }
`;

export default function ArtistsArchive() {

  let { artisturi } = useParams();
  const { loading, error, data } = useQuery(GET_ARTISTS, {
    variables: {
      slug: artisturi
  }
  });
console.log(artisturi);
  const helmetContext = {};
  useEffect(() => {
    if(data) {

      const tl = gsap.timeline();

document.querySelectorAll(".gridItem a").forEach(function(element) {
  const img = element.querySelectorAll("img");
  const imgAll = document.querySelectorAll("img");
  const animation = gsap.timeline({ paused: true });
  const animationAll = gsap.timeline({ paused: true });
  const text = element.querySelectorAll(".fades");

  animationAll.to(imgAll, {opacity: 0.5, duration: .1, ease: "power1.inOut"});
  animation
  .to(img, 
    {
      scale: 1, 
      opacity: 1, 
      duration: .5, 
      delay: .5,
      ease: "power1.inOut"
    }
    )
    .to(text,
      {
        opacity: 1,
        duration: .5,
        delay: .5,
        ease: "power1.inOut"
      }
    )
    ;

    const imgzoom2 = document.querySelectorAll(".gridItem");

imgzoom2.forEach((element) => {

    tl.set(element, { scale: 0 });
    setTimeout(() => {
    tl.to(element, {
        autoAlpha:1,
        delay: 0.11,
        scale: 1,
        stagger: { // wrap advanced options in an object
          grid: [3,16],
          ease: "power1.inOut",
        }
    });
  }, 250);
})
  element.addEventListener("mouseenter", function () {
    animation.play();
  });
  element.addEventListener("mouseleave", function () {
    animation.reverse();
  });

});

    }});


    if(loading) {
      return (
          <div id="loading"></div>
      )
    }
  else if(data) {
  return (
    <HelmetProvider context={helmetContext}>
<Helmet>
       <body id="works"></body>
      </Helmet>
      <div className="standardScroll">
      <section className="pageTitle">
        <h1>Works</h1>
      </section>
    <section id="worksArchive" className="">
      {data.artists.nodes[0].projects.nodes
      .map((flexFields, key) => {
      const obj = flexFields;
      for (const key in obj) {
        return (
            <div className={`gridItem span1`} data-title={`pro_${obj.projectId}_pro`} key={obj.projectId}>
              <Link to={`/projects/${obj.slug}`}>
              <div className="gridTitle" data-title={`pro_${obj.projectId}_pro`} ><p>{obj.title}</p></div>
                <div className="zoomed">
                <img className="zoomMe" srcSet={obj.featuredImage?.node?.srcSet} alt={obj.featuredImage?.node?.altText} />
                </div>
                </Link>
            </div>
        )
      }
      return null;
    } ) }
            
    </section>
    </div>
    </HelmetProvider>

  );
  }
}

import React, {useEffect, useState} from "react";
import '../css/contact.css';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

const GET_CONTACT = gql`
query getContacts {
  page(id: "15", idType: DATABASE_ID) {
    seo {
      fullHead
    }
  }
  themeGeneralSettings {
    contactDetails {
      address
      emailAddress
      socialLinks {
        fieldGroupName
        socialMediaLink
        socialMediaName
      }
      contactImage {
        sourceUrl
      }
    }
  }
}
`;

export default function ContactPage() {

  const { loading, error, data } = useQuery(GET_CONTACT);
  if(loading) {
    return (
        <div id="loading"></div>
    )
  }
  else if(data) {
      const fullHead = parse(data.page.seo.fullHead);
      const helmetContext = {};
      let styles = {
        backgroundImage: "url('" + data.themeGeneralSettings.contactDetails?.contactImage?.sourceUrl + '',
        
      };
  return (
    <>
     <HelmetProvider context={helmetContext}>
      <Helmet>
      <body id="contactPage"></body>
      {fullHead}
      </Helmet>
      <section className="pageTitle">
        <h1 className="large">Contact</h1>
        </section>
    <div id="contactBlock" className="contactContainer small">
      <div>
        <a target="_blank" href={`mailto:${data.themeGeneralSettings.contactDetails?.emailAddress}`}>
        {data.themeGeneralSettings.contactDetails?.emailAddress}
        </a>
      </div>
      <div>
        <a target="_blank" href={data.themeGeneralSettings.contactDetails?.socialLinks[0].socialMediaLink}>{data.themeGeneralSettings.contactDetails?.socialLinks[0].socialMediaName}</a>
        
      </div>
      <address>
        <div dangerouslySetInnerHTML={{__html:data.themeGeneralSettings.contactDetails?.address}}>
        </div>
      </address>

    </div>
    <div className="contactEnvelope" style={styles}>

    </div>
    </HelmetProvider>

    </>
  );
  }
}
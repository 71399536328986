import React from "react";

export default function SmallProjectVideo(post) {
  
  const {
    obj
  } = post;
  
    return (
      <div className={obj.fieldGroupName}>
        <div className="videoContainer">
        <span className="imgreveal"></span>
        <video loop="true" autoplay="autoplay" id="vid" muted playsinline preload>
          <source src={obj.video.mediaItemUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
          <div className="textOverImage">
            <h3 className="small">{obj.optionalHeading}</h3>
            <hr />
            <div dangerouslySetInnerHTML={{__html: obj.optionalContent}}></div>
          </div>
        </div>
      </div>
    );
  }  
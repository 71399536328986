import React from "react";
import { NavLink } from "react-router-dom";



export default function MainMenu({ menu }) {
  const {
    label,
    uri,
    cssClasses,
  } = menu;

  

  return (
    
      <li key={uri + ' ' + cssClasses} className={cssClasses[0] + ' ' + cssClasses[1]}>
      <NavLink to={uri} activeclassname="active"className={`nav-link ${label}`}>
        {label}
      </NavLink>
      </li>
  );
}
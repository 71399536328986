import React, {useEffect} from "react";
import "../css/featured.css";
import { useMediaQuery } from 'react-responsive'
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { gsap } from "gsap-trial";
import { Draggable } from "gsap-trial/Draggable";

gsap.registerPlugin(Draggable);

const GET_FEATURED = gql`
  query getAllPosts {
    projects(where: {categoryName: "Featured"}) {
        edges {
          node {
            title
            slug
            featuredImage {
              node {
                altText
                srcSet
                title
                sourceUrl(size: _2048X2048)
              }
            }
            artists {
              nodes {
                name
              }
            }
            featuredWidths {
              portraitFeaturedImage {
                srcSet
                sourceUrl(size: _2048X2048)
              }
            }
          }
        }
      }
  }
`;



export default function FeaturedProjects() {

  const handleMediaQueryChange = (matches) => {
    // matches will be true or false based on the value for the media query
  }

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' }, undefined,  handleMediaQueryChange)

  const { loading, error, data } = useQuery(GET_FEATURED);
 
  useEffect(() => {
    let mounted = true

    if(mounted) {
      if (data) {

      const tl2 = gsap.timeline();


      if(!!window.IntersectionObserver){
        let observer = new IntersectionObserver((entries, observer) => { 
          entries.forEach(entry => {
          if(entry.isIntersecting){
            tl2.to(entry.target.querySelector("#featuredProjects #container"), 
              {
                x: "100vw",
                duration: .5,
                ease: "power1.inOut"
              }
            )
            .to(entry.target.querySelector("#feattitle"),
              {
                y: "100vh",
                duration: .5,
                ease: "power1.inOut"
              }
            )
            .to(entry.target.querySelector("#featartist"),
              {
                y: "-25px",
                duration: .5,
                ease: "power1.inOut"
              }
            );
            observer.unobserve(entry.target);
          }
          });
        }, {rootMargin: "0px 0px 0px 0px"});
        document.querySelectorAll('#page-container').forEach(img => { observer.observe(img) });
      }
      
      else;

      
  if(!!window.IntersectionObserver){
    let observerTwo = new IntersectionObserver((entries, observerTwo) => { 
      entries.forEach(entry => {
      if(entry.isIntersecting){
        gsap.to(entry.target.querySelectorAll('a'),
          {
          y: "-25px",
          ease: "power1.in",
          duration: 1,
        });
        observerTwo.unobserve(entry.target);
      }
      });
    }, {rootMargin: "0px 0px 0px 0px"});
    document.querySelectorAll('#allWorksLink').forEach(img => { observerTwo.observe(img) });
  }
  
  else;


    

      
      let down =document.querySelector("#next");
      let up =document.querySelector("#prev");

      setTimeout(function() {
        const throttled = (delay, fn) => {
          let lastCall = 0;
          return function (...args) {
            const now = new Date().getTime();
            if (now - lastCall < delay) {
              return;
            }
            lastCall = now;
            return fn(...args);
          };
        };
        
        const movableElementsWrapper = document.querySelector(
          "#root"
        );
        const speed = 1;
        
        const items = gsap.utils.toArray(".movable").map(element => {
          return {
            element,
            shiftValue: element.getAttribute("data-value") / 250,
            xSet: gsap.quickSetter(element, "x", "px"),
            ySet: gsap.quickSetter(element, "y", "px"),
          }
        });
        
        const mouse = {
          x: 0,
          y: 0
        };
        
        const mouseMoveHandler = (e) => {
          mouse.x = e.x;
          mouse.y = e.y;
        };
        
        movableElementsWrapper.onmousemove = mouseMoveHandler;
        
        gsap.ticker.add(() => {
          const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());
          
          items.forEach(item => {
            item.xSet(item.shiftValue * mouse.x * dt);
            item.ySet(item.shiftValue * mouse.y * dt);
          });
        });
      }, 1000);
    setTimeout(function () {

  let lastX = 0;
let direction;
let animDirection;
Draggable.create(document.createElement("div"), {
  trigger: "#container",
  type: "x",
  minimumMovement: 10,
  onDragStart: function() {
    if (inAnimation && inAnimation.isActive()) {
      // inAnimation.timeScale(10);
      // outAnimation.timeScale(10);
      gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
      
      if (this.getDirection() === "left") {
        nextSlide = slides[currentSlide.index - 1] || slides[slides.length - 1];
      } else {
        nextSlide = slides[currentSlide.index + 1] || slides[0];
      }
    } else if (this.getDirection() === "left") {
      setSlide(slides[currentSlide.index - 1] || slides[slides.length - 1]);
    } else {
      setSlide(slides[currentSlide.index + 1] || slides[0]);
    }
  }
});

let inAnimation = null;
let outAnimation = null;
let nextSlide = null;
let $container = document.getElementById("container");
let $containerWidth = $container.offsetWidth;
gsap.set("#container", { perspective: $containerWidth * 2 });
gsap.set(".slide", {
  backfaceVisibility: "hidden",
  transformOrigin: "50% 50% -" + $containerWidth / 2
});

window.addEventListener("resize", function() {
  let $containerWidth = $container.offsetWidth;

  gsap.set("#container", { perspective: $containerWidth * 2 });
  gsap.set(".slide", {
    transformOrigin: "50% 50% -" + $containerWidth / 2
  });
});

document.onkeydown = checkKey;

function checkKey(e) {

    e = e || window.event;

    if (e.keyCode == '38') {
        nextSlide = slides[currentSlide.index - 1] || slides[slides.length - 1];
  if (inAnimation && inAnimation.isActive()) {
    gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
  } else {
    setSlide(nextSlide);
  }
    }
    else if (e.keyCode == '40') {
        nextSlide = slides[currentSlide.index + 1] || slides[0];
        if (inAnimation && inAnimation.isActive()) {
          gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
        } else {
          setSlide(nextSlide);
        }
    }
    else if (e.keyCode == '37') {
        nextSlide = slides[currentSlide.index - 1] || slides[slides.length - 1];
        if (inAnimation && inAnimation.isActive()) {
          gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
        } else {
          setSlide(nextSlide);
        }
    }
    else if (e.keyCode == '39') {
        nextSlide = slides[currentSlide.index + 1] || slides[0];
  if (inAnimation && inAnimation.isActive()) {
    gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
  } else {
    setSlide(nextSlide);
  }
    }

}

async function simulateMouseClick(el) {
  let opts = {view: window, bubbles: true, cancelable: true, buttons: 1};
  el.dispatchEvent(new MouseEvent("mousedown", opts));
  await new Promise(r => setTimeout(r, 50));
  el.dispatchEvent(new MouseEvent("mouseup", opts));
  el.dispatchEvent(new MouseEvent("click", opts));
}


const debounce = function(fn, d) {
  let timer;
  return function(e) {
   
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(e);
    }, d);
  }
}

function moveSlide(e) {
  if(e.deltaY > 0) {
    simulateMouseClick(down);
  } else {
    simulateMouseClick(up);
  }
}

const scrollSection = (e) => {
moveSlide(e);
}

window.addEventListener("wheel", debounce(scrollSection, 210));


document.getElementById("prev").addEventListener("click", function() {
  nextSlide = slides[currentSlide.index - 1] || slides[slides.length - 1];
  if (inAnimation && inAnimation.isActive()) {
    gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
  } else {
    setSlide(nextSlide);
  }
});

document.getElementById("next").addEventListener("click", function() {
  nextSlide = slides[currentSlide.index + 1] || slides[0];
  if (inAnimation && inAnimation.isActive()) {
    gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
  } else {
    setSlide(nextSlide);
  }
});


let slideDelay = 1.5;
let slideDuration = 1;

let slideElements = document.querySelectorAll(".slide");
let slides = Array.prototype.map.call(slideElements, createSlide);

slides.forEach(function(slide, i) {
  slide.next = slides[i + 1] || slides[0];
  slide.duration = slideDuration;
});

let currentSlide = slides[0];


//let autoPlay = gsap.delayedCall(slideDelay, setSlide);


function setSlide(slide) {
  //autoPlay.restart(true);

  if (slide === currentSlide) {
    return;
  }

  if (currentSlide.index === 0 && slide.index === slides.length - 1) {
    currentSlide.setPrevInactive();
    currentSlide = slide;
    currentSlide.setPrevActive();
  } else if (currentSlide.index === slides.length - 1 && slide.index === 0) {
    currentSlide.setInactive();
    currentSlide = slide;
    currentSlide.setActive();
  } else if (slide.index < currentSlide.index) {
    currentSlide.setPrevInactive();
    currentSlide = slide;
    currentSlide.setPrevActive();

  } else {
    currentSlide.setInactive();
    currentSlide = slide;
    currentSlide.setActive();
  }
}

function createSlide(element, index) {
  let slide = {
    next: null,
    duration: 0,
    index: index,
    element: element,
    isActive: false,
    setActive: setActive,
    setInactive: setInactive,
    setPrevActive: setPrevActive,
    setPrevInactive: setPrevInactive
  };
  if(element) {

  if (index === 0) {
    gsap.set(element, { autoAlpha: 1 });
    const feTit = document.getElementById("feattitle");
    const feArt = document.getElementById("featartist");
    let feTitle = element.getAttribute("data-title");
    let feArtist = element.getAttribute("data-artist");
    element.parentNode.classList.add("current");
    if(feTit) {
      feTit.innerHTML = feTitle;
      }
      if(feArt) {
      feArt.innerHTML = feArtist;
      }
  } else {
    gsap.set(element, { autoAlpha: 0 });
    element.parentNode.classList.remove("current");
  }
}

  function setActive() {
    if(element) {

    animDirection = 1;
    slide.isActive = true;
    inAnimation = gsap.fromTo(
      element,
      slide.duration,
      { rotationY: -90, rotation:0.01, autoAlpha: 1 },
      { rotationY: 0, rotation:0.01, onComplete: callNext }
    );
    const feTit = document.getElementById("feattitle");
    const feArt = document.getElementById("featartist");
    let feTitle = element.getAttribute("data-title");
    let feArtist = element.getAttribute("data-artist");
    element.parentNode.classList.add("current");
    if(feTit) {
    feTit.innerHTML = feTitle;
    }
    if(feArt) {
    feArt.innerHTML = feArtist;
    }
    }
  }

  function setPrevActive() {
    if(element) {
    animDirection = -1;
    slide.isActive = true;
    inAnimation = gsap.fromTo(
      element,
      slide.duration,
      { rotationY: 90, rotation:0.01, autoAlpha: 1 },
      { rotationY: 0, rotation:0.01, onComplete: callNext }
    );
    const feTit = document.getElementById("feattitle");
    const feArt = document.getElementById("featartist");
    let feTitle = element.getAttribute("data-title");
    let feArtist = element.getAttribute("data-artist");
    element.parentNode.classList.add("current");
    if(feTit) {
      feTit.innerHTML = feTitle;
      }
      if(feArt) {
      feArt.innerHTML = feArtist;
      }
    }
  }

  function setInactive() {
    slide.isActive = false;
    outAnimation = gsap.to(element, slide.duration, {
      rotationY: 90,
      rotation:0.01,
      onComplete: setAlpha
    });
    element.parentNode.classList.remove("current");
  }

  function setPrevInactive() {
    slide.isActive = false;
    outAnimation = gsap.to(element, slide.duration, {
      rotationY: -90,
      rotation:0.01, 
      onComplete: setAlpha
    });
    element.parentNode.classList.remove("current");

  }

  function setAlpha() {
    let $this = this.target;
    gsap.set($this, { autoAlpha: 0 });
  }

  function callNext() {
    if (nextSlide) {
      setSlide(nextSlide);
      nextSlide = null;
    }
  }

  return slide;
  
}
/** DOTS */
const target = document.getElementById("container");
let current = document.querySelector(".current");
    let aDot = current.dataset.number;
    let newDot = document.querySelector(".dot[data-number='" + aDot + "']");
    newDot.classList.add("active");
// create an observer instance
var observer = new MutationObserver(function(mutations) {
  mutations.forEach(function(mutation) {
    let current = document.querySelector(".current");
    if(current) {
    let currentDotAll = document.querySelector(".active");
    let aDot = current.dataset.number;
    let newDot = document.querySelector(".dot[data-number='" + aDot + "']");
    currentDotAll.classList.remove("active");
    newDot.classList.add("active");
    }
  });    
});

// configuration of the observer:
var config = { attributeFilter: ['class'], childList: true, subtree: true };

// pass in the target node, as well as the observer options
observer.observe(target, config);

/** DOTS */
}, 0);
    } 
  }
  return function cleanup() {
    mounted = false
}

  
  }, [data]);
  if(loading) {
    
  }
  else if(data) {
    
  return (
    <>
    <div className="featCont"><h2 id="feattitle" className="large"></h2><p id="featartist" className="small"></p></div>
    <section id="featuredProjects" className="">
      <div id="container">
      {data.projects.edges
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
      const obj = flexFields;
      for (const key in obj) {
        let mobimg = obj.node.featuredWidths?.portraitFeaturedImage?.sourceUrl;
        let desImg = obj.node.featuredImage?.node?.sourceUrl;
        let mobimgwebp = obj.node.featuredWidths?.portraitFeaturedImage?.sourceUrl + '.webp';   
        let desImgwebp = obj.node.featuredImage?.node?.sourceUrl + '.webp';
        if(mobimg) {
          mobimg = mobimg;
          mobimgwebp = mobimgwebp;
        } else {
          mobimg = desImg;
          mobimgwebp = desImgwebp;
        }
        return (
            <Link key={obj.node.slug} className="slideLink" data-number={obj.node.title} to={`/projects/${obj.node.slug}`}>
            <div className="slide movable" data-value="3" data-title={obj.node.title} data-artist={obj.node.artists.nodes[0].name}>
              <img className="slideImage" alt={obj.node.featuredImage?.node?.altText} src={ isMobile ? mobimgwebp : desImgwebp } 
              onError={(e)=>{ if (e.target.src !== isMobile ? mobimg : desImg){
                e.target.onerror = null;
                 e.target.src= isMobile ? mobimg : desImg;}
            }
       }
                />
            </div>
            </Link>
        )
      }
      return null;
    } ) }
        </div>

        
      <div className="dots">
      {data.projects.edges
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
      const obj = flexFields;
      for (const key in obj) {
        return (
          <div key={obj.node.title} className="dot" data-number={obj.node.title}></div>
        )
        
      } } ) }
      </div>
      
            <div className="controls">
            <div id="prev">Prev</div>
            <div id="next">Next</div>
            </div>
    </section>
    </>
  );
}
}

import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { Canvas} from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { OrbitControls, OrthographicCamera } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense } from "react";
import { useEffect } from "react";
import { gsap } from "gsap-trial";
import "../css/splash.css";



const Model = () => {
  const gltf = useLoader(GLTFLoader, "/final-vf.glb");
  

  return (
    <>
   
      <primitive object={gltf.scene} scale={0.5} makedefault/>
      
    </>
  );
};


export default function Splash() {
  window.sessionStorage.setItem("Splash", "seen");
const tl = gsap.timeline();
const helmetContext = {};

useEffect(() => {
  document.getElementById('root').classList.add('updated');

  setTimeout(function () {
    const element = document.getElementById('refThree');
  element.classList.add('updated');
  }, 2000);

  setTimeout(function () {
    const element = document.getElementById('showMe');
  gsap.to(element,{y: "-100px", duration: 1})
  }, 3000);
  
// Enter Site
document.addEventListener('click', function (event) {
	if (!event.target.matches('#showMe')) return;
  
	event.preventDefault();
	const element = document.getElementById('refThree');

tl.to("#showMe",{
  y: "-125px", duration: 0.5
})
.to(element, {
  className: null,
  delay: 0
})
.to("#top",{width: "100%",immediateRender: false,
autoRound: false,})
.to("#right",{height: "100%",immediateRender: false,
autoRound: false,})
.to("#bottom",{width: "100%",immediateRender: false,
autoRound: false,})
.to("#left",{height: "100%",immediateRender: false,
autoRound: false,});
//.to("#root", {borderColor: "#000", ease: 'linear', duration: 4,});

setTimeout(function () {
  window.sessionStorage.setItem("Splash", "seen");
  window.location.reload(false); 
}, 4500);



}, false);


}, []);


  return (
    <>
     <HelmetProvider context={helmetContext}>
<Helmet>
  <meta name="description" content="VISION FACTORY - LEADING ART DIRECTION AND PERFORMANCE DESIGN." data-rh="true" />
    <meta property="og:locale" content="en_GB" data-rh="true" />
      <meta property="og:type" content="article" data-rh="true" />
        <meta property="og:title" content="Home - Vision Factory" data-rh="true" />
          <meta property="og:description" content="VISION FACTORY - LEADING ART DIRECTION AND PERFORMANCE DESIGN." data-rh="true" />
            <meta property="og:site_name" content="Vision Factory" data-rh="true" />
              <meta property="article:modified_time" content="2023-03-02T21:00:11+00:00" data-rh="true" />
                <meta name="twitter:card" content="summary_large_image" data-rh="true" />
       <body id="splash"></body>
      </Helmet>
    <span id="top"></span>
    <span id="right"></span>
    <span id="bottom"></span>
    <span id="left"></span>

    <div  id="smooth-wrapper" className="container">
     
    <div id="smooth-content" className="App">
      <Canvas id="refThree" >
      <group >
      <ambientLight intensity={10} />
      <directionalLight
        intensity={10}
      />
        <Suspense fallback={null}>
          <Model />

          <OrbitControls autoRotate enableZoom={true} enablePan={false} minAzimuthAngle="Math.PI" maxAzimuthAngle="Math.PI" />
        </Suspense>
        <OrthographicCamera 
        zoom={100}
        position={[15, 15, 15]}
        makeDefault
         />
        </group>
      </Canvas>
      <div id="slideUp">
      <a id="showMe" href="/" onClick={window.sessionStorage.setItem("Splash", "seen")}>Enter</a>
      </div>
    </div>
    
    </div>
    </HelmetProvider>
    </>
  );
  
}
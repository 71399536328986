import React from "react";
import MainMenu from "./components/menu";
import { gql, useQuery } from "@apollo/client";

const GET_MENU = gql`
query ProjectId {
  menus(where: {location: MENU_1}) {
    edges {
      node {
        name
        menuItems {
          nodes {
            uri
            label
            cssClasses
          }
        }
      }
    }
  }
}`;

export default function Header() {
  const { data } = useQuery(GET_MENU);



  const postsFound = Boolean(data?.menus.edges[0].node.menuItems.nodes.length);
  if (!postsFound) {
    return null;
  }

  return (
    <header id="masthead">
      <div id="dynamicMenu">
        <ul className="mainMenu">
        {data.menus.edges[0].node.menuItems.nodes.map((menu) => (
          <MainMenu key={menu.uri} menu={menu} />
          ))}
        </ul>
      </div>
    </header>
  );
}
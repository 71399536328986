import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import CopyPage from '../components/copy';


const Layout =({children}) =>{
    

    return(
        <>
        <Header />
        <main id="main">      
        <div id="page-container" className="page-container">
        {children}
        </div>
        <div className="copy">
        <CopyPage />
        </div>
        </main>
        <Footer />
        </>
    )
}


export default Layout;
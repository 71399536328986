import React, {useState} from "react";
import WorksArchive from "../components/WorksArchive";
import WorksList from "../components/WorksList";

export default function AllWorksPage() {
        
  const [cName, setClassName] = useState('jsListView');  

  return (
    
    <>
    <div className="standardScroll" id={cName}>
    <section className="pageTitle">
        <h1 className="large">Works</h1>
        <div className="textMode">
          <div className="gridView">
      <button 
      onClick={() => setClassName('jsGridView')}
      title="Grid View"
      className="imageTog small">Grid</button> 
      </div> 
      <div className="listView">
      <button 
      onClick={() => setClassName('jsListView')}
      title="List View"
      className="textTog small">List</button>
      </div>
    </div>
      </section>
    <section id="worksArchive" className={cName}>
    { cName === 'jsGridView' ? <WorksArchive /> : <WorksList /> } 
    </section>
    </div>
    </>
  );
}
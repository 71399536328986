import { ApolloLink, ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

const link = ApolloLink.from([
    new HttpLink({
      uri: 'https://cms.vision-factory.co.uk/graphql',
      useGETForQueries: true
    })
  ]);
  
  const client = new ApolloClient({
    ssrMode: true,
    link,
    cache: new InMemoryCache(),
  });

export default client;
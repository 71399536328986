import React from "react";

export default function CreditsBlock(post) {
    const { 
      obj
    } = post;
    
    return (
      <div id={obj.fieldGroupName}>
                <div className="hideShowCredits">
        <h2 className="small">Credits</h2>
        <hr />
        <div className="creditDiv">
        {obj.addTextBlocks.map((anObjectMapped, index) => {
          return (
            <div key={anObjectMapped.heading} className="creditItems">
              <h3 className="small">{anObjectMapped.heading}</h3>
              <br />
              <p dangerouslySetInnerHTML={{__html:anObjectMapped.text}}></p>
              <br />
              <br />
            </div>
          );
        })
        }
        </div>
        </div>
      </div>
    );
  }  
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Splash from './components/Splash';

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
 }
 window.addEventListener('resize', appHeight);
 window.addEventListener('orientationchange', appHeight);
 appHeight();

const root = ReactDOM.createRoot(document.getElementById('root'));


const splash = window.sessionStorage.getItem("Splash");

if (splash) {
  root.render(
    <>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      </>
  );
} else {
  
  root.render(
    <>
    <Splash />
    </>
    );
}


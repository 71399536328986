import React from "react";

export default function SmallSingleProjectImage(post) {
  
  const {
    obj
  } = post;
  
    return (
      <div className={obj.fieldGroupName}>
        <div className="imageContainer">
          <span className="imgreveal"></span>
          <img src={obj.addImage.sourceUrl + '.webp'} onError={(e)=>{ if (e.target.src !== obj.addImage.sourceUrl){
                e.target.onerror = null;
                 e.target.src= obj.addImage.sourceUrl;}
            }
       }/>
          <div className="textOverImage">
            <h3 className="small">{obj.optionalHeading}</h3>
            <hr />
            <div dangerouslySetInnerHTML={{__html: obj.optionalContent}}></div>
          </div>
        </div>
      </div>
    );
  }  
import React, {useEffect} from "react";
import { gsap } from "gsap-trial";


export default function OpenMenu() {
  const openM = document.getElementById("menuButton");

  var tl = gsap.timeline();


  useEffect(() => {
      setTimeout(function () {

        document.querySelectorAll(".nav-link").forEach(function(element) {
          element.addEventListener("click", function(e){
            if(element.classList.contains("Home") || element.classList.contains("Works")) {
              setTimeout(() => {
                tl.reversed(!tl.reversed());
                }, 1000)
            } else {
            
            }
          })
          });
        
  function openNav() {
    theMenu();
    var navBtn = document.getElementById("menuButton");

  navBtn.onclick = function (e) {
    // Toggle reversed to it's opposite value
    tl.reversed(!tl.reversed());
  }
  
  }

  
  function theMenu() {
    
  tl
  .fromTo("#Path_1",
   {
    strokeWidth: 1,
    stroke: "#000",
    fill: "#fff",
  },
  {
    strokeWidth: 1,
    stroke: "#fff",
    fill: "none",
    duration: 0.2,
    ease: "Power3.easeOut",
  }
  )
  .fromTo("#masthead",
    {
    
    left: 'calc(-100% - 2px)',
    display: 'none',
    width: 0,  
    padding: 0,
    },
    {
      left: "-1px",
      width: "100%",
      display: 'block',
      duration: 0.5,
      padding: '15px 15px 15px 15px',
    ease: 'power1.inOut',
    }
  )
  
  .fromTo("#masthead li",
  {
   opacity: 0,
   left: "-100%",
  },
    {
      delay: 0.4,
      duration: 0.4,
    opacity: 1,
    left: "0%",
    ease: 'power1.inOut',
    }
  )
  .fromTo(".copy", 
  {
    duration: 0.4,
    bottom: '-100%',
    height: 0,  
  },
  {
    height: "auto",
    bottom: "0%",
    position: "absolute",
    duration: 0.4,
  }
  )
  .fromTo(".page-container", 
  {
    height: "100%",  
  },
  {
    duration: 0.5,
    height: "calc(100%)",
    ease: 'power1.inOut',
  }
  )
    
  .reverse();
  }

  openNav();
}, 3000);


  
}, [openM]);

let menuButton = document.querySelectorAll(".mainMenu li a");
menuButton.onclick = function (e) {

  tl.reverse();
}
  

  return (
    <div id="openMenu" className="openMenu">
        <button id="menuButton">
        <svg id="Group_2" data-name="Group 2" xmlns="http://www.w3.org/2000/svg" width="50" height="57.738" viewBox="0 0 50 57.738">
          <g id="Group_1" data-name="Group 1" clipPath="url(#clip-path)">
            <path id="Path_1" data-name="Path 1" d="M45.666,21.554,44.611,5.593a1.643,1.643,0,0,0-.864-1.336,4.192,4.192,0,0,0-4.165,0,1.646,1.646,0,0,0-.866,1.336l-.507,7.669L33.333,4.811,29.165,7.216,25,0,0,14.433V43.3L25,57.738,50,43.3V24.057l-4.332-2.5Z" fill="#fff" stroke="#000"/>
          </g>
        </svg>
        </button>
    </div>
  );
  
}
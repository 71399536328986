import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom/client';
import '../css/worksList.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { gsap } from "gsap-trial";


const GET_CLIENTS = gql`
  query getAllPosts {
    clients(first: 100) {
      nodes {
        name
        slug
        projects {
          nodes {
            title
            slug
            projectId
            featuredImage {
              node {
                altText
                srcSet
                title
              }
            }
            featuredWidths {
              chooseImageAspectRatio
            }
          }
        }
      }
    }
  }
`;

export default function OurClientsMobile() {

  const { loading, error, data } = useQuery(GET_CLIENTS);

  const helmetContext = {};

  useEffect(() => {
    if(!!window.IntersectionObserver){
      let observer = new IntersectionObserver((entries, observer) => { 
        entries.forEach(entry => {
        if(entry.isIntersecting){
          gsap.to(entry.target.querySelectorAll('p'),
            {
            y: "25px",
            ease: "power1.in",
            duration: 1,
          });
          observer.unobserve(entry.target);
        }
        });
      }, {rootMargin: "0px 0px 0px 0px"});
      document.querySelectorAll('.clListTitle').forEach(img => { observer.observe(img) });
    }
    
    else;

  });

  if(loading) {
    return (
        <div id="loading"></div>
    )
  }
  else if(data) {
  return (
    <HelmetProvider context={helmetContext}>
<Helmet>
       <body id="works"></body>
      </Helmet>
      <section className="pageTitle">
        <h1 className="large">Clients</h1>
        </section>
    <section id="worksProjects" className="mobile">
      <div id="standardContainer">
      {data.clients.nodes
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
      const obj = flexFields;
      for (const key in obj) {
        if(obj.projects.nodes[0].featuredImage?.node?.srcSet) {
        return (
            <div key={obj.slug} className="clList" data-title={`pro_${obj.projects.nodes[0].projectId}_pro`}>
              <Link to={`/client/${obj.slug}`}>
              <div className="clListTitle" data-title={`pro_${obj.projects.nodes[0].projectId}_pro`}>
                <p className="small">{obj.name}</p>
                </div>
                </Link>
                </div>        )
        }}
      return null;
    } ) }
        </div>
            
    </section>
    </HelmetProvider>

  );
  }
}

import React from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

const GET_COPY = gql`
query getCopy {
  acfOptionsFooter {
    footerDetails {
      copyrightText
      designCredits
    }
  }
}
`;

export default function CopyPage() {

  const { loading, error, data } = useQuery(GET_COPY);
  if(loading) {
    return (
        <div id="loading"></div>
    )
  }
  else if(data) {

  return (
    <>
    <div id="copyBlock" className="small">
      
      <div className="copyCopy">
        <span>
        ©{(new Date().getFullYear())} {data.acfOptionsFooter.footerDetails?.copyrightText}<span dangerouslySetInnerHTML={{__html:data.acfOptionsFooter.footerDetails?.designCredits}}></span></span>
         
      </div>
      

    </div>
    
    </>
  );
  }
}
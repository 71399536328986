import React, {useEffect, useState, useRef} from "react";
import '../css/worksAll.css';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { gsap } from "gsap-trial";


const GET_WORKS = gql`
  query getAllPosts {
    projects (first:100) {
        edges {
          node {
            title
            projectId
            slug
            artists {
              edges {
                node {
                  id
                  name
                }
              }
            }
            featuredImage {
              node {
                altText
                srcSet
                sourceUrl(size: _1536X1536)
                title
              }
            }
            
          }
        }
      }
  }
`;

export default function WorksArchive() {

  const { loading, error, data } = useQuery(GET_WORKS);

  const helmetContext = {};

  useEffect(() => {
    if(data) {
    
      const tl = gsap.timeline();


      
if(!!window.IntersectionObserver){
  let observer = new IntersectionObserver((entries, observer) => { 
    entries.forEach(entry => {
    if(entry.isIntersecting){
      gsap.to(entry.target.querySelectorAll('img'),
        {
        x: "200%",
        ease: "sine.in",
        duration: 1,
        stagger: {
          each: 0.4,
          from: "start",
          grid: "auto"
        }
      });
      observer.unobserve(entry.target);
    }
    });
  }, {rootMargin: "0px 0px 0px 0px"});
  document.querySelectorAll('.gridItem').forEach(img => { observer.observe(img) });
}

else;


document.querySelectorAll(".gridItem a").forEach(function(element) {
  const img = element.querySelectorAll("img");
  const imgAll = document.querySelectorAll("img");
  const animation = gsap.timeline({ paused: true });
  const animationAll = gsap.timeline({ paused: true });
  const text = element.querySelectorAll(".fades h3");
  const text2 = element.querySelectorAll(".fades h4");


  animationAll.to(imgAll, {opacity: 0.5, duration: .1, ease: "power1.inOut"});
  animation
  .to(img, 
    {
      scale: 1, 
      opacity: 1, 
      duration: .5, 
      ease: "power1.inOut"
    }
    )
  .to(text,
    {
      y: "50px",
      duration: .5,
      ease: "power1.inOut"
    }
  )
  .to(text2,
    {
      y: "-50px",
      duration: .5,
      ease: "power1.inOut"
    }
  )
    ;

  element.addEventListener("mouseenter", function () {
    animation.play();
  });
  element.addEventListener("mouseleave", function () {
    animation.reverse();
  });

});

    }});

   
  
    
  if(loading) {
   
  }
  else if(data) {
  return (
    <HelmetProvider context={helmetContext}>
<Helmet>
       <body id="works"></body>
      </Helmet>
      
      <div className="masonry">
      {data.projects.edges
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
      const obj = flexFields;
     
      for (const key in obj) {
        if(obj.node.featuredImage?.node?.srcSet) {
          
        return (
            <div key={obj.node.slug} className={`gridItem`} data-title={`pro_${obj.node.projectId}_pro`}>
              <Link to={`/projects/${obj.node.slug}`}>
              <div className="gridTitle fades" data-title={`pro_${obj.node.projectId}_pro`} ><h3 className="small">{obj.node.title}</h3></div>
                <div className="zoomed">
                <img className="zoomMe" src={obj.node.featuredImage?.node?.sourceUrl + '.webp'} alt={obj.node.featuredImage?.node?.alt} onError={(e)=>{ if (e.target.src !== obj.node.featuredImage?.node?.sourceUrl){
                e.target.onerror = null;
                 e.target.src= obj.node.featuredImage?.node?.sourceUrl;}
            }
       }/>
                </div>
                <div className="gridArtist fades small"><h4>{obj.node.artists?.edges[0].node.name}</h4></div>
                </Link>
            </div>
        )
        }
      }
      return null;
    } ) }
            </div>
    </HelmetProvider>

  );
  }
}

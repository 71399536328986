import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom/client';
import '../css/worksList.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { gsap } from "gsap-trial";


const GET_CLIENTS = gql`
  query getAllPosts {
    clients(first: 100) {
      nodes {
        name
        slug
        projects {
          nodes {
            title
            slug
            projectId
            featuredImage {
              node {
                altText
                srcSet
                title
              }
            }
            featuredWidths {
              chooseImageAspectRatio
            }
          }
        }
      }
    }
  }
`;

export default function ClientsPage() {

  const { loading, error, data } = useQuery(GET_CLIENTS);

  const helmetContext = {};

const bgColor = '#000';
const tl =  gsap.timeline({
        
});

  
useEffect(() => {
  if(!!window.IntersectionObserver){
    let observer = new IntersectionObserver((entries, observer) => { 
      entries.forEach(entry => {
      if(entry.isIntersecting){
        gsap.to(entry.target.querySelectorAll('p'),
          {
          y: "25px",
          ease: "power1.in",
          duration: 1,
        });
        observer.unobserve(entry.target);
      }
      });
    }, {rootMargin: "0px 0px 0px 0px"});
    document.querySelectorAll('.clListTitle').forEach(img => { observer.observe(img) });
  }
  
  else;
});
//console.log(node);


// line
function WeClicked(e) {


  let block = e.target.parentNode.parentNode.getAttribute('data-title');

  
  

  function letsAnim() {
    tl.set(".clList[data-title=" + block + "]",
      {
        className: "clList active"
      }
    )
     
    .fromTo(".clList[data-title=" + block + "] .straightLine",
       {
        width: 0, 
        background: bgColor,
        immediateRender: false,
        autoRound: false,
       
       }, 
       {
        width: '66.66667%', 
        background: bgColor
       }
    )
    
    // top
    .fromTo(".clList[data-title=" + block + "] .square",
        {
          opacity: 0, 
          visibility: 'hidden',
          display: "none",
         },
         {
          opacity: 1, 
          visibility: 'visible',
          display: "block",
         }
        )
    
    
    // right
    .fromTo(".clList[data-title=" + block + "] .right-side",
       {
        height: 0, 
        background: bgColor,
        immediateRender: false,
        autoRound: false,
       
       }, 
       {
        height: '100%', 
        background: bgColor
       }
    )
    
    // bottom
    .fromTo(".clList[data-title=" + block + "] .bottom-side",
       {
        width: 0, 
        background: bgColor,
        immediateRender: false,
        autoRound: false,
       
       }, 
       {
        width: '100%', 
        background: bgColor
       }
    )
    
    // left
    .fromTo(".clList[data-title=" + block + "] .left-side",
       {
        height: 0, 
        background: bgColor,
        immediateRender: false,
        autoRound: false,
       
       }, 
       {
        height: '100%', 
        background: bgColor
       }
    )
    .fromTo(".clList[data-title=" + block + "] .squareImage",
    {
      opacity: 0, 
      visibility: 'hidden',
      immediateRender: false,
      autoRound: false,
     
     }, 
     {
      opacity: 1, 
      visibility: 'visible'
     }
    )
    .fromTo(".clList[data-title=" + block + "] .pageTitle",
    {
      opacity: 0, 
      visibility: 'hidden'
     },
    {
      opacity: 1, 
      visibility: 'visible'
     }
    )
      } // lets anim

  function letsAnimAll() {
    tl.fromTo(".clList.active .square",
    {
      opacity: 1, 
      visibility: 'visible',
      display: "block",
     },
     {
      opacity: 0, 
      visibility: 'hidden',
      display: "none",
     }
    )
     .fromTo(".clList.active .straightLine",
    {
      width: '66.6667%', 
      background: bgColor
     },
       {
        width: 0, 
        background: bgColor,
        immediateRender: false,
        autoRound: false,
       
       }
       
    )
     .set(".clList.active",
      {
        className: "clList"
      }
    )
     
   
      } // lets animall


      const active = document.querySelectorAll('.clList.active');
      if(active.length === 0) {
        letsAnim();
      } else if(active.length === 1) {
        letsAnimAll();
        if(!e.target.parentNode.parentNode.classList.contains('active')) {
          letsAnim();
        }
      } else {
        if(!e.target.parentNode.parentNode.classList.contains('active')) {
          letsAnim();
        } 
      }
    

  


  }



  if(loading) {
    return (
        <div id="loading"></div>
    )
  }
  else if(data) {
  return (
    <HelmetProvider context={helmetContext}>
<Helmet>
       <body id="works"></body>
      </Helmet>
    <section id="worksProjects" className="">
      <div id="standardContainer">
      {data.clients.nodes
      .map((flexFields, key) => {
      const obj = flexFields;
      console.log(obj);
      for (const key in obj) {
        if(obj.projects.nodes[0]) {
        return (
            <div key={obj.projects.nodes[0].slug} className="clList" data-title={`pro_${obj.projects.nodes[0].projectId}_pro`}>
              <div className="clListTitle" data-title={`pro_${obj.projects.nodes[0].projectId}_pro`} onClick={WeClicked}><p className="small">{obj.name}</p></div>
              <div className="clListImage">
              <div className="straightLine"></div>
              <div className={`square ${obj.projects.nodes[0].featuredWidths?.chooseImageAspectRatio}`}>
              <Link to={`/client/${obj.slug}`}><img className="squareImage" srcSet={obj.projects.nodes[0].featuredImage?.node.srcSet} /></Link>
                <span className="left-side"></span>
                <span className="top-side"></span>
                <span className="right-side"></span>
                <span className="bottom-side"></span>
                <div className="pageTitle">
                  <p className="small"><Link to={`/client/${obj.slug}`}>Details</Link></p>
                </div>
              </div>
              </div>
              </div>
        )
        }}
      return null;
    } ) }
        </div>
            
    </section>
    </HelmetProvider>

  );
  }
}

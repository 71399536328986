import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

const SITEMAP= gql`
query getContacts {
  page(id: "984", idType: DATABASE_ID) {
    seo {
      fullHead
    }
  }
  contentNodes(first: 400, where: {status: PUBLISH}) {
    nodes {
      ... on Project {
        slug
        contentTypeName
        title
        status
      }
      ... on Page {
        slug
        contentTypeName
        title
        status
      }
    }
  }
  clients(first: 400) {
    nodes {
      slug
    }
  }
}`;

export default function Sitemap() {
        
  const {  loading, data } = useQuery(SITEMAP);
  if(loading) {
    return (
        <div id="loading"></div>
    )
  }
  if(data) {
  const fullHead = parse(data.page.seo.fullHead);
  const helmetContext = {};
  console.log(data);
  return (
    
    <>
      <HelmetProvider context={helmetContext}>
      <Helmet>
      <body id="sitemap"></body>
      {fullHead}
      </Helmet>
      <section className="pageTitle">
        <h1 className="large">Sitemap</h1>
        </section>
        <section id="smooth-wrapper" className="Sitemapcontainer">
         <h3>Pages</h3>
        {data.contentNodes.nodes
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
        const obj = flexFields;
if (flexFields.__typename === 'Page' && flexFields.status === 'publish') {
  return (
    <p>
<Link to={`/${obj.slug}`}>{`https://vision-factory.co.uk/${obj.slug}`}</Link>
</p>
  )
}
      })
    }
   <h3>Projects</h3>
   {data.contentNodes.nodes
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
        const obj = flexFields;

if (flexFields.__typename === 'Project' && flexFields.status === 'publish') {
  return (
    <p>
<Link to={`/projects/${obj.slug}`}>{`https://vision-factory.co.uk/projects/${obj.slug}`}</Link>
</p>
  )
}
      })
    }
    <h3>Clients</h3>
    {data.clients.nodes
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
        const obj = flexFields;
if (flexFields.__typename === 'Client') {
  return (
    <p>
<Link to={`/client/${obj.slug}`}>{`https://vision-factory.co.uk/client/${obj.slug}`}</Link>
</p>
  )
}
      })
    }
    </section>

    </HelmetProvider>

    </>
  );
}
}